import React from 'react';
import Select, { GroupBase, StylesConfig } from 'react-select';

import style from './customSelect.module.scss';
interface OptionType {
  value: string;
  label: string;
}

interface CustomSelectProps {
  options?: OptionType[];
  placeholder?: string;
  components?: any;
  label?: string;
}

const customStyles: StylesConfig<OptionType, false, GroupBase<OptionType>> = {
  control: (provided: any, state: any) => ({
    ...provided,
    borderBottom: state?.selectProps?.menuIsOpen ? 'none' : '1px solid #868E96',
    borderBottomRightRadius: state?.selectProps?.menuIsOpen && 0,
    borderBottomLeftRadius: state?.selectProps?.menuIsOpen && 0,
    fontFamily: 'Gotham500',
    fontSize: '14px',
    boxShadow: 'none',
    borderColor: '#868E96',
    '&:hover': {
      borderColor: '#868E96'
    }
  }),
  dropdownIndicator: (provided) => ({
    ...provided,
    padding: 0
  }),
  indicatorSeparator: () => ({
    display: 'none'
  }),
  placeholder: (provided) => ({
    ...provided,
    textAlign: 'left',
    fontFamily: 'Gotham500',
    fontSize: '14px'
  }),
  singleValue: (provided) => ({
    ...provided,
    textAlign: 'left',
    fontFamily: 'Gotham500',
    fontSize: '14px'
  }),
  menu: (provided) => ({
    ...provided,
    textAlign: 'left',
    marginTop: 0,
    borderTopRightRadius: '0px',
    borderTopLeftRadius: '0px',
    fontFamily: 'Gotham500',
    fontSize: '14px',
    width: '99.8%',
    marginLeft: '1px'
  }),

  option: (provided, state) => ({
    ...provided,
    textAlign: 'left',
    borderBottom: '1px solid #ccc',
    marginTop: '-0.3rem',
    backgroundColor: state.isSelected ? 'black' : 'white',
    cursor: 'pointer',
    fontFamily: 'Gotham500',
    fontSize: '14px',
    '&:hover': {
      backgroundColor: '#e0e0e0'
    },
    height: '46px',
    padding: '8px',
    display: 'flex',
    alignItems: 'center'
  })
};

export const CustomSelect: React.FC<CustomSelectProps> = ({
  options,
  placeholder,
  components,
  label
}) => (
  <div className={style.customSelectContainer}>
    {label && (
      <label htmlFor={`inputId-${label}`} className={style.customSelectLabel}>
        {label}
      </label>
    )}
    <Select
      options={options}
      components={components && { Menu: components }}
      placeholder={placeholder}
      styles={customStyles}
      id={`inputId-${label}`}
    />
  </div>
);
