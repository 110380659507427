/* eslint-disable @typescript-eslint/naming-convention */
import React, { ReactNode, RefObject, useEffect, useState } from 'react';

import { AddIcon } from '../../assets/svgs/addIcon';
import { AddProjectIcon } from '../../assets/svgs/addProject';
import { DownloadButtonIcon } from '../../assets/svgs/downloadButtonIcon';
import { DownloadPdf } from '../../assets/svgs/downloadPdf';
import { MenuIcon } from '../../assets/svgs/menuIcon';
import Rating from '../../assets/svgs/rating';
import WhiteRatings from '../../assets/svgs/whiteRatings';
import { handlePDFDownload } from '../../utils/pdf';
import { generatePageTemplate } from '../ProjectEvaluation/Header';
import AccordionRow from '../shared/AccordionRow/AccordionRow';
import Breadcrumb from '../shared/Breadcrumb/Breadcrumb';
import RCButton from '../shared/Button/Button';
import ButtonContainer from '../shared/ButtonContainer';
import { Container } from '../shared/Container';
import CustomButton from '../shared/CustomButton';
import HubSpotForm from '../shared/HubspotForm';
import Loader from '../shared/Loader/loader';
import { ModalComponent } from '../shared/Modal/Modal';
import ProductContributionGridHeader from '../shared/ProductContributionGridHeader/ProductContributionGridHeader';

import ModalBody from './modalBody';
import { ModalFooter } from './modalFooter';
import { ModalHeader } from './modalHeader';

import style from './productDetails.module.scss';

interface AccordionData {
  id: number;
  value: boolean;
}

const breadcrumbItems = [
  { href: '/', text: 'Parent' },
  {
    href: '/child',
    text: 'Child'
  }
];
const buttonInfoIcons: { [key: string]: JSX.Element } = {
  plus: <AddIcon />,
  download: <DownloadButtonIcon />,
  certificate: <WhiteRatings />,
  comment: <AddIcon />
};
const menuIcons: { [key: string]: JSX.Element } = {
  plus: <MenuIcon />,
  comment: <MenuIcon />
};

interface ProductDetailProps {
  data: Record<string, any> | undefined;
  pdfRef: RefObject<HTMLDivElement>;
}

export const ProductDetail: React.FC<ProductDetailProps> = ({ data, pdfRef }) => {
  const [accordionContentShow, setaccordionContentShow] = useState<AccordionData[]>([]);
  const [modalType, setModalType] = React.useState<string | null>(null);
  const [isModalOpen, setIsModalOpen] = React.useState<boolean>(false);
  const [isPdfLoading, setIsPdfLoading] = useState(false);
  const [showCrossIcon] = useState<boolean>(true);
  const onHeaderClick = (index: number): any => {
    setaccordionContentShow((prevData) =>
      prevData?.map((data) => ({
        ...data,
        value: Number(data.id) === Number(index) ? !data.value : data.value
      }))
    );
  };
  const shouldShowCrossIcon = !(
    modalType === 'add-to-project' || modalType === 'document-contributions'
  );

  useEffect(() => {
    setaccordionContentShow(
      data?.lens?.map((data: any, index: number) => ({
        id: index,
        value: true
      }))
    );
  }, [data?.lens]);

  interface ModalContent {
    header: ReactNode;
    body: ReactNode;
    footer: ReactNode;
  }

  type ModalContentMap = {
    [key: string]: ModalContent;
  };

  const modalContent: ModalContentMap = {
    'add-to-project': {
      header: (
        <ModalHeader
          Icon={<AddProjectIcon />}
          label=" Add to project"
          description="Create a new project or add to an existing project"
        />
      ),
      body: <ModalBody />,
      footer: <ModalFooter onClose={() => closeModal()} />
    },
    'contact-us': {
      header: null,
      body: (
        <HubSpotForm
          setIsModalOpen={() => closeModal()}
          formId={'e0039aea-79ec-4b93-9954-00bc583142ae'}
        />
      ),
      footer: null
    },
    'contact-manufacturer': {
      header: null,
      body: (
        <HubSpotForm
          setIsModalOpen={() => closeModal()}
          formId={'dca4dac0-f43f-4510-a5fc-2416b2bfd6cc'}
        />
      ),
      footer: null
    },
    'document-contributions': {
      header: (
        <ModalHeader
          Icon={<Rating productDetail />}
          label="Credit Evaluation"
          description="Check credits for LEED, WELL, Living Building Challenge and other ratings systems and standards. "
        />
      ),
      body: <ModalBody ratings />,
      footer: <ModalFooter onClose={() => closeModal()} />
    }
  };
  const openModal = (modalType: string): void => {
    setModalType(modalType);
    setIsModalOpen(true);
  };
  const closeModal = (): void => {
    setModalType(null);
    setIsModalOpen(false);
  };
  const getModalContent = (): ModalContent =>
    modalType ? modalContent[modalType] : { header: null, body: null, footer: null };

  return (
    <Container>
      <nav>
        <Breadcrumb navItems={breadcrumbItems} />
      </nav>
      <header>
        <div className={style.productEvaluationSection}>
          <div className={style.productMedaDataSection}>
            <Container>
              <div className={style.productDetails}>
                <div className={style.productImgSection}>
                  <img src={data?.product?.image} alt="product" className={style.productImg} />
                </div>
                <div className={style.productMetaDataContainer}>
                  <div className={style.productMetaData}>
                    <div className={style.productCategory}>
                      {!!data?.product['key-features'] &&
                        data.product['key-features']?.map((feature: any) => (
                          <div className={style.productCategorykeys}>
                            <div className={style.productCategoryInformation}>{feature?.name}:</div>
                            <div className={style.productCategoryValues}>
                              {Array.isArray(feature?.value) ? (
                                <div>{feature.value.join(', ')}</div>
                              ) : (
                                <div>{feature?.value}</div>
                              )}
                            </div>
                          </div>
                        ))}
                    </div>
                    <div className={style.productBrandName}>
                      {data?.product['brand-name'] || ''}
                    </div>
                    <div className={style.productName}>{data?.product?.name || ''}</div>
                    <div className={style.productDescription}>
                      {data?.product?.description || ''}
                    </div>
                  </div>
                  <div className={style.downloadButtons}>
                    <RCButton
                      onClick={() => {
                        handlePDFDownload(
                          pdfRef,
                          setIsPdfLoading,
                          generatePageTemplate,
                          `${data?.product['brand-name']?.toLowerCase()}-${data?.product.sku}-ecomedes-evaluation`
                        );
                      }}
                      className={`${style.pdfButton} ${style.downloadButtonContent} hidePdfElements`}
                      variant="primary">
                      <span className={style.downloadPdfText}>DOWNLOAD PDF</span>
                      {isPdfLoading ? <Loader /> : <DownloadPdf productEval />}
                    </RCButton>
                  </div>
                </div>
              </div>
            </Container>
          </div>
        </div>
      </header>
      <div className={style.actionButtonContainer}>
        <Container>
          <div className={style.actionButtonsSection}>
            {data &&
              data['action-buttons']?.map((info: any) => (
                <ButtonContainer
                  Icon={buttonInfoIcons[info.icon] ?? ''}
                  text={info.text ?? ''}
                  MenuIcon={menuIcons[info.icon] ?? ''}
                  openModal={openModal}
                  modalType={info?.type || ''}
                />
              ))}
          </div>
        </Container>
      </div>

      <main>
        <div className={style.ratingSystem}>
          <Container>
            <div className={style.contactUsSection}>
              <div className={style.contactUsDetails}>
                <h3 className={style.contactUsDetailsTitle}>Is this your product?</h3>
                <p className={style.contactUsDetailsDescription}>
                  Contact us to enrich product information or add product listings.
                </p>
              </div>
              <div className={style.contactUsButtons}>
                <div onClick={() => openModal('contact-us')}>
                  <CustomButton label="contact us" className={style.contactUsButtonsStyle} />
                </div>
                <CustomButton
                  label="contact manufacturer"
                  className={style.contactUsButtonsStyle}
                />
              </div>
            </div>
            <h3 className={style.ratingSystemHeader}>Details</h3>

            {!!data?.lens &&
              data?.lens?.map((lensValue: any, lensIndex: number): any => (
                <div style={{ marginBottom: '2rem' }}>
                  <div key={`lens-${lensIndex}`}>
                    <ProductContributionGridHeader
                      key={`header-${lensIndex}`}
                      name={lensValue?.name || ''}
                      image={lensValue?.image || ''}
                      notes={lensValue?.notes || ''}
                      lensDetails={lensValue?.attributes || []}
                      productPage
                      onHeaderClick={onHeaderClick}
                      index={lensIndex}
                      accordionContentShow={accordionContentShow}
                    />

                    <div className={style.productTable}>
                      <div
                        className={`${
                          accordionContentShow[lensIndex]?.value
                            ? `${style.contentShow} ${style.transition}`
                            : `${style.contentHide} ${style.transition}`
                        } pdfContentShow`}>
                        <div>
                          {lensValue?.attributes?.map((detailsData: any, detailsIndex: number) => (
                            <AccordionRow
                              key={`row-${detailsData}-${detailsIndex}`}
                              title={detailsData?.name || ''}
                              value={detailsData?.value || ''}
                              type={detailsData?.kind || ''}
                              tooltipText={detailsData?.hint || ''}
                              isToolTip
                              isLensData
                              isLightContent
                            />
                          ))}
                          <div
                            onClick={() => onHeaderClick(lensIndex)}
                            className={`${style.accordionFooter} pdfContentHide`}>
                            {accordionContentShow[lensIndex]?.value ? 'SHOW LESS ' : 'SHOW MORE'}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
          </Container>
        </div>
      </main>
      <ModalComponent
        isOpen={isModalOpen}
        setIsModalOpen={closeModal}
        showCrossIcon={shouldShowCrossIcon && showCrossIcon}
        modalHeader={getModalContent()?.header || null}
        modalBody={getModalContent()?.body || null}
        modalFooter={getModalContent()?.footer || null}
      />
    </Container>
  );
};
