import { useState } from 'react';

import { CarbonDioxide } from '../../assets/svgs/carbonDioxide';
import Certifications from '../../assets/svgs/certification';
import Circularity from '../../assets/svgs/circularity';
import { EcomedesLogo } from '../../assets/svgs/ecomedesLogo';
import Energy from '../../assets/svgs/energy';
import LeedCredits from '../../assets/svgs/leedCredits';
import { Materials } from '../../assets/svgs/materials';
import Medal from '../../assets/svgs/medal';
import Rating from '../../assets/svgs/rating';
import { ThumbsDownIcon } from '../../assets/svgs/thumbsDownIcon';
import { ThumbsUpIcon } from '../../assets/svgs/thumbsUpIcon';
import { WaterDropIcon } from '../../assets/svgs/waterDrop';
import Wind from '../../assets/svgs/wind';
import { Badge } from '../shared/Badge/Badge';
import HubSpotForm from '../shared/HubspotForm';
import IconBox from '../shared/IconBox/iconBox';
import { ModalComponent } from '../shared/Modal/Modal';

import GreenTags from './greenTags/GreenTags';

import style from './aiSummary.module.scss';

interface AIInfterface {
  ai_summary?: string;
  brandIdent?: string;
  sku?: string;
  siteIdent?: string;
  productUrl?: string;
  green_tags?: IGreenTag[];
}
interface IGreenTag {
  badges?: string[];
  icon_name?: string;
  label?: string;
}

export const greenTagsIcons: { [key: string]: JSX.Element } = {
  ratings_icon: <Rating greenTags />,
  certifications_icon: <Certifications greenTags />,
  leed_credits_icon: <LeedCredits />,
  circularity_icon: <Circularity greenTags />,
  indoor_air_quality_icon: <Wind />,
  carbon_icon: <CarbonDioxide />,
  material_health_icon: <Medal />,
  mindful_materials_icon: <Materials />,
  water_icon: <WaterDropIcon greenTags />,
  energy_icon: <Energy greenTags />
};

export const AISummary = ({ data }: { data?: AIInfterface }): JSX.Element => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [showCrossIcon, setshowCrossIcon] = useState(true);
  const toggle = (): void => setIsModalOpen(!isModalOpen);
  const closeModal = (): void => {
    setIsModalOpen(false);
  };

  return (
    <div className={style.aiSummaryContainer}>
      {data?.ai_summary && (
        <div
          className={
            !!data?.green_tags?.length
              ? `${style.aiSummaryHighlights} ${style.aiSummaryHighlightsBorder}`
              : `${style.aiSummaryHighlights}`
          }>
          <div className={style.aiSummaryHighlightsHeader}>
            <div className={style.ecomedesIcon}>
              <IconBox icon={<EcomedesLogo />} />
            </div>
            <div className={style.highlightsLabel}>
              <p className={style.highlightsLabelText}>Sustainability Highlights</p>
            </div>
          </div>
          <div className={style.aiSummaryDescription}>{data?.ai_summary || ''}</div>
          <div className={style.aiSummaryFooter}>
            <p className={style.aiSummaryFooterText}>
              *This description was generated by AI. Was this helpful?
            </p>
            <div className={style.footerIconSection}>
              <div className={style.footerIcons}>
                <div onClick={() => toggle()}>
                  <ThumbsUpIcon />
                </div>
                <div onClick={() => toggle()}>
                  <ThumbsDownIcon />
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      <div className={style.aiSummaryTagsSection}>
        <div className={style.aiSummaryTagsSubSection}>
          {data?.ai_summary ? (
            ''
          ) : (
            <div className={style.aiSummaryHighlightsHeader}>
              <div className={style.ecomedesIcon}>
                <IconBox icon={<EcomedesLogo />} />
              </div>
              <div className={style.highlightsLabel}>
                <p className={style.highlightsLabelText}>Sustainability Highlights</p>
              </div>
            </div>
          )}
          {!!data?.green_tags &&
            data?.green_tags?.map((greenTags, index) => (
              <div
                key={index}
                className={`${style.tagsContainer} ${data?.green_tags && index !== data?.green_tags?.length - 1 && style.active}`}>
                <div className={style.lensInfo}>
                  <div className={style.greenTagsIcon}>
                    {greenTags?.icon_name ? greenTagsIcons[greenTags?.icon_name] : ''}
                  </div>
                  <p className={style.tagsLabel}>{greenTags.label}</p>
                </div>
                <GreenTags>
                  {greenTags?.badges?.map((badge, index) => (
                    <Badge key={index} className={style.aiSummaryTags} content={badge} />
                  ))}
                </GreenTags>
              </div>
            ))}
        </div>
      </div>

      <ModalComponent
        isOpen={isModalOpen}
        setIsModalOpen={closeModal}
        showCrossIcon={showCrossIcon}
        modalBody={
          <HubSpotForm
            formId="5cd7805b-7c6e-45fb-a695-4c7d0f753d67"
            toggle={toggle}
            setIsModalOpen={closeModal}
            setshowCrossIcon={setshowCrossIcon}
            aiSummarydata={data}
          />
        }
      />
    </div>
  );
};
