import React from 'react';

interface CustomButtonProps {
  label?: string;
  className?: string;
}
const CustomButton: React.FC<CustomButtonProps> = ({ label, className }) => (
  <div className={className}>{label}</div>
);

export default CustomButton;
