import style from './buttonContainer.module.scss';
interface ButtonContainerProps {
  Icon?: any;
  text?: string;
  MenuIcon?: any;
  openModal: (modalType: string) => void;
  modalType: string;
}
const ButtonContainer: React.FC<ButtonContainerProps> = ({
  Icon,
  text = 'label',
  MenuIcon,
  openModal,
  modalType
}) => (
  <div data-testid={text} className={style.container} onClick={() => openModal(modalType)}>
    <div className={style.buttonInfo}>
      <div className={style.iconContainer}>{Icon}</div>
      <h3 className={style.buttonLabel}>{text}</h3>
    </div>
    {MenuIcon}
  </div>
);

export default ButtonContainer;
