import React from 'react';

import style from './productDetails.module.scss';
interface ModalHeaderProps {
  Icon?: any;
  label?: string;
  description?: string;
}
export const ModalHeader: React.FC<ModalHeaderProps> = ({ Icon, label, description }) => (
  <div className={style.modalHeaderAddProjectContainer}>
    <div className={style.modalHeaderTitleandIcon}>
      {Icon}
      <h1 className={style.modalHeaderLabel}>{label}</h1>
    </div>
    <p className={style.modalHeaderDescription}>{description}</p>
  </div>
);
