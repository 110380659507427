import React from 'react';

import { stateOptions } from '../../utils/selectOptions';
import { CustomInput } from '../shared/CustomInput';
import { CustomMenu } from '../shared/CustomMenu';
import { CustomSelect } from '../shared/CustomSelect';

import style from './productDetails.module.scss';
interface ModalBodyProp {
  ratings?: boolean;
}
const ModalBody: React.FC<ModalBodyProp> = ({ ratings = false }) => (
  <div>
    <form className={style.addProjectModalForm}>
      <CustomSelect
        label={ratings ? 'Rating Systems, Standards, and Frameworks' : 'Projects'}
        options={stateOptions}
        {...(!ratings && { components: CustomMenu })}
        placeholder={'Select project'}
      />

      {!ratings && (
        <div className={style.addProjectModalFormInputContainer}>
          <CustomInput type="text" label={'Project Name'} placeholder={'Enter project name'} />
        </div>
      )}
      <div className={style.addProjectModalFormInputContainer}>
        <CustomInput
          type="text"
          label={ratings ? 'Postal / Zip Code' : 'Postal Code'}
          placeholder={'Enter postal code'}
        />
      </div>
    </form>
  </div>
);

export default ModalBody;
