import { ReactNode } from 'react';
import { RxCross2 } from 'react-icons/rx';

import styles from './Modal.module.scss';

export const ModalComponent = ({
  isOpen = false,
  modalBody,
  modalHeader,
  modalFooter,
  setIsModalOpen,
  showCrossIcon = false
}: {
  isOpen?: boolean;
  modalHeader?: ReactNode;
  modalBody?: ReactNode;
  modalFooter?: ReactNode;
  setIsModalOpen?: () => void;
  showCrossIcon?: boolean;
}): JSX.Element => (
  <div className={`${isOpen ? styles.overlay : styles.hideModal}`}>
    <div className={styles.customModal}>
      {showCrossIcon && (
        <div data-testid="modal-overlay" className={styles.crossIcon} onClick={setIsModalOpen}>
          <RxCross2 fontSize={20} />
        </div>
      )}
      <ModalHeader>{modalHeader}</ModalHeader>
      {modalBody}
      <ModalFooter>{modalFooter}</ModalFooter>
    </div>
  </div>
);

const ModalHeader = ({ children }: { children: any }): JSX.Element => <div>{children}</div>;
const ModalFooter = ({ children }: { children: any }): JSX.Element => <div>{children}</div>;
